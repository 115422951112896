import React from "react";
import ReactDatetime from "react-datetime";
import moment from "moment";

const readOnlyStyle = {
  backgroundColor: "#FFFFFF",
  cursor: "pointer"
}

function CustomReactDatetime(props) {
  const tomorrow = moment().add(1, 'day').format("YYYY-MM-DD");
  const valid = function( current ){
    return current.isBefore(tomorrow);
  };
  const customProps = {
    ...props,
    inputProps: {
      ...props.inputProps,
      style: readOnlyStyle,
      readOnly: true
    },
    isValidDate: valid,
  }
  return (
    <>
      <ReactDatetime
        {...customProps}
      ></ReactDatetime>
    </>
  );
}

export default CustomReactDatetime;
