/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://fgyrrl46svbkxhnnqfkyrbjgvq.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-2:0868b21d-4500-437a-9b1d-14b3441e9155",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_w7H7HZ1al",
    "aws_user_pools_web_client_id": "7umk3j87stai3j6pbtdbqmdaki",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "mcchubdashboard-hostingbucket-prodhub",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d13u8v0j1bujq2.cloudfront.net"
};


export default awsmobile;
