import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  OverlayTrigger,
  Table,
  Tooltip,
  Container,
  Row,
  Col
} from "react-bootstrap";

function CreateSupportEvent() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="3" sm="6">
            CreateSupportEvent
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CreateSupportEvent;
